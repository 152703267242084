<template>
    <!-- 协议页面 -->
    <div>
        <!-- 筛选 -->
        <div class="table-page-search-wrapper" style="margin-bottom: 18px">
            <a-form layout="inline">
                <a-row :gutter="24">
                    <!-- 按钮 -->
                    <a-col :md="6" :sm="24">
                        <a-button type="primary" @click="freshTableData">刷新</a-button>
                    </a-col>
                </a-row>
            </a-form>
        </div>
        <a-card :bordered="false">
            <a-table :data-source="dataList" :columns="columns" align="center" :pagination="false" :loading="loading">
                <span slot="num" slot-scope="text, record, index">{{ index + 1 }}</span>
                <span slot="action" slot-scope="text, record">
                    <a @click="$refs.editForm.edit(record)">编辑</a>
                </span>
            </a-table>
            <!-- 分页组件 -->
            <div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
                <a-pagination :pageSize="page.pageSize" :current="page.pageNo" show-size-changer
                    :page-size-options="['10', '20', '50', '100']" show-quick-jumper :total="total"
                    :show-total="(total) => `共 ${total} 条`" @change="pageChange" @showSizeChange="sizeChange">
                </a-pagination>
            </div>
            <edit-form ref="editForm" @ok="handleOk" />
        </a-card>
    </div>
</template>
<script>
import editForm from './editForm'
import { agreePageList } from '@/api/modular/mallLiving/agreeFeed'
export default {
    components: {
        editForm
    },
    data() {
        return {
            // 高级搜索 展开/关闭
            advanced: false,
            // 查询参数
            queryParam: {
                name: undefined,
                status: undefined,
                orgId: undefined
            },
            total: 16,
            pageSizeOptions: ['10', '20', '30', '50'],
            page: {
                pageNo: 2,
                pageSize: 10,
            },
            // 表头
            columns: [{
                title: '序号',
                dataIndex: 'num',
                key: 'num',
                scopedSlots: { customRender: 'num' },
                align: 'center',
                width: 80
            },
            {
                title: '协议类型',
                dataIndex: 'name',
                align: 'center',
            },
            {
                title: '更新时间',
                dataIndex: 'updateTime',
                align: 'center',
            },
            {
                title: 'code值',
                dataIndex: 'code',
                align: 'center',
            },
            {
                title: '操作',
                dataIndex: 'action',
                fixed: 'right',
                align: 'center',
                scopedSlots: { customRender: 'action' },
            }],
            dataList: [],
            loading: false,
            statusDictTypeDropDown: [], // 0草稿 1发布 2撤回 3删除
            typeDictTypeDropDown: [], // 0通知 1公告
            orgList: []
        }
    },
    mounted() {
        this.freshTableData()
    },
    methods: {
        freshTableData() {
            this.page.pageNo = 1
            this.queryTableData()
        },
        pageChange(e) {
            this.page.pageNo = e
            this.queryTableData()
        },
        sizeChange(pageNo, pageSize) {
            this.page.pageNo = pageNo
            this.page.pageSize = pageSize
            this.queryTableData()
        },

        queryTableData() {
            this.loading = true
            var params = {
                page: this.page,
                queryParam: this.queryParam
            }
            agreePageList(params).then((res) => {
                if (res.success) {
                    this.dataList = res.data.rows
                    this.total = res.data.totalRows
                    this.loading = false
                }
            }).finally((res) => {
                setTimeout(() => {
                    this.loading = false
                }, 800)
            })
        },

        handleOk() {
            this.queryTableData()
        }
    },
}
</script>
<style lang="less">
.table-operator {
    margin-bottom: 18px;
    display: flex;
    flex-direction: row-reverse;
}

button {
    margin-right: 8px;
}

.add-task-con {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 10px;
}
</style>
